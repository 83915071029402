import { useEffect, useState } from 'react';
import axios from 'axios';
function Server() {
  const [services, setServices] = useState([
    { name: "Crowns", url: "https://crowns.monstermy.city/monitor/server", cpu: 0, memory: 0, disk: 0 },
    { name: "Lore", url: "https://lore.monstermy.city/monitor/server", cpu: 0, memory: 0, disk: 0 },
    { name: "Landing", url: "https://monstermy.city/monitor/server", cpu: 0, memory: 0, disk: 0 },
    { name: "API", url: "https://api.monstermy.city/monitor/server", cpu: 0, memory: 0, disk: 0 },
    { name: "Mint", url: "https://api.monstermy.city/monitor/server", cpu: 0, memory: 0, disk: 0 },
  ])
  const init = async () => {
    const url = "https://crowns.monstermy.city/monitor/servers"
    let updateServices: any = []
    try {
      axios.get(url).then((res: any) => {
        updateServices = res.data.map((s: any) => ({
          ...s,
          memory: s.freemem / 1024 / 1000,
          disk: s.disk.free / 1024 / 1000,
          status: [200, 204].includes(s.status) ? "OK" : "ERROR",
        }));
        setServices(updateServices)
      })
    } catch (ex) {
      console.log(ex)
    }

    setServices(updateServices)
  }
  const start = () => {
    init()
    const timer = setTimeout(() => {
      start()
    }, 5 * 60 * 1000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    start()
  }, []);

  return (
    <div className='server'>
      <table>
        <thead>
          <tr>
            <th>Server</th>
            <th>CPU</th>
            <th>Memory</th>
            <th>Disk</th>
          </tr>
        </thead>
        <tbody>
          {services.map((s: any) =>
            <tr key={s.name}>
              <td className={s.status === "OK" ? "success" : "error"}>{s.name}</td>
              <td>{s.cpu}%</td>
              <td>{s.memory.toFixed(2)} MB</td>
              <td>{s.disk.toFixed(2)} MB</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Server;
