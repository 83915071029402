import { useEffect, useState } from 'react';
import axios from 'axios';
import Server from '../organisms/Server';
import Service from '../organisms/Service';
function Home() {

  return (
    <div className='home'>
      <Server />
      <Service />
    </div>
  );
}

export default Home;
