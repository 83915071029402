import { useEffect, useState } from 'react';
import axios from 'axios';
function Service() {
  const [services, setServices] = useState([
    { name: "PocketBase", url: "https://apilab.monstermy.city/api/collections/attributes/records?perPage=500&filter=((is_active=true)(type=%27background%27))", status: "NA", time: 0 },
    { name: "Advocates", url: "https://advocates.monstermy.city/api/supplies/", status: "NA", time: 0 },
    { name: "Cities", url: "https://cities.monstermy.city/api/countries", status: "NA", time: 0 },
    { name: "M2Cash", url: "https://events.m2.cash", status: "NA", time: 0 },
    { name: "Mint/Create", url: "https://api.monstermy.city/api/mint", status: "NA", time: 0 },
  ])
  const init = async () => {
    const loadingServices = services.map((s: any) => ({ ...s, status: "Loading" }))
    const updateServices: any = []
    setServices(loadingServices)
    const promises = services.map(async (service: any) => {
      try {
        const start = new Date().getTime();

        const data = await axios.get(service.url);
        updateServices.push({
          ...service,
          time: new Date().getTime() - start,
          status: [200, 204].includes(data.status) ? "OK" : "ERROR",
        });
      } catch (ex) {
        updateServices.push(service)
        console.log(ex)
      }
    });

    // services.forEach((service) => {
    //   const start = new Date().getTime();

    //   console.log(service.name)
    //   axios.get(service.url)
    //     .then((data: any) => {
    //       const updateService: any = services.find((s: any) => s.name === service.name) || {};
    //       updateService.status = [200, 204].includes(data.status) ? "OK" : "ERROR";
    //       updateService.time = new Date().getTime() - start;
    //       updateServices.push(updateService)
    //     })
    //     .catch(() => {
    //       updateServices.push(service)
    //       console.log("Error")
    //     })
    // })
    await Promise.all(promises);
    setServices(updateServices)
  }
  const start = () => {
    init()
    const timer = setTimeout(() => {
      start()
    }, 5 * 60 * 1000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    start()
  }, []);
  return (
    <div className='service'>
      <table>
        <thead>
          <tr>
            <th>Service</th>
            <th>Status</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {services.map((s: any) =>
            <tr key={s.name}>
              <td>{s.name}</td>
              <td className={s.status === "OK" ? "success" : "error"}>{s.status}</td>
              <td>{s.time}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Service;
